@import url('https://fonts.googleapis.com/css2?family=Kanit&family=Teko&family=Luckiest+Guy:wght@400;600;700&display=swap');

:root {
  --primary-color: #627327;
  --primary-light-color: #96a61c;
  --primary-dark-color: #30402c;
  --secondary-color: #4c592e;
  --secondary-light-color: #bfb26f;
  --light-yellow: #f5d340;
  --dark-yellow: #efc200;
  --light-green: #498b00;
  --dark-green: #366600;
  --light-blue: #57b0c3;
  --cloud-blue: #a0edfe;
  --red: #ff0f00;
  --smoke: #444444;
  --burnt: #2f2f2f;
  --text-main: #f9f9f9;
  --text-dark: #333;
  --input-back: #e8f7ff;
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Teko', sans-serif;
}

p,
small {
  font-family: 'Kanit', sans-serif;
  /*font-size: 18px;*/
}

a {
  text-decoration: none;
  color: var(--light-yellow);
}

.video-grid > div:nth-of-type(1) {
  grid-column: 1/2;
  grid-row: 1/3;
  /* margin-left: -50px; */
}

.video-grid > div:nth-of-type(2) {
  grid-column: 2/3;
  grid-row: 2/4;
}

.video-grid > div:nth-of-type(3) {
  grid-column: 3/4;
  grid-row: 3/5;
}

.video-grid > div:nth-of-type(4) {
  grid-column: 4/5;
  grid-row: 4/6;
}

.video-grid > div:nth-of-type(5) {
  grid-column: 5/6;
  grid-row: 5/7;
}

.video-grid > div:nth-of-type(6) {
  grid-column: 6/7;
  grid-row: 6/8;
}

.video-grid > div:nth-of-type(7) {
  grid-column: 1/2;
  grid-row: 3/5;
  /* margin-left: -50px; */
}

.video-grid > div:nth-of-type(8) {
  grid-column: 2/3;
  grid-row: 4/6;
}

.video-grid > div:nth-of-type(9) {
  grid-column: 3/4;
  grid-row: 5/7;
}

.video-grid > div:nth-of-type(10) {
  grid-column: 4/5;
  grid-row: 6/8;
}

.video-grid > div:nth-of-type(11) {
  grid-column: 5/6;
  grid-row: 7/9;
}

.video-grid > div:nth-of-type(12) {
  grid-column: 6/7;
  grid-row: 8/10;
}

.video-grid > div:nth-of-type(13) {
  grid-column: 1/2;
  grid-row: 5/7;
  /* margin-left: -50px; */
}

.video-grid > div:nth-of-type(14) {
  grid-column: 2/3;
  grid-row: 6/8;
}

.video-grid > div:nth-of-type(15) {
  grid-column: 3/4;
  grid-row: 7/9;
}

.video-grid > div:nth-of-type(16) {
  grid-column: 4/5;
  grid-row: 8/10;
}

.video-grid > div:nth-of-type(17) {
  grid-column: 5/6;
  grid-row: 9/11;
}

.video-grid > div:nth-of-type(18) {
  grid-column: 6/7;
  grid-row: 10/12;
}

.video-grid > div:nth-of-type(19) {
  grid-column: 1/2;
  grid-row: 7/9;
  /* margin-left: -50px; */
}

.video-grid > div:nth-of-type(20) {
  grid-column: 2/3;
  grid-row: 8/10;
}

.video-grid > div:nth-of-type(21) {
  grid-column: 3/4;
  grid-row: 9/11;
}

.video-grid > div:nth-of-type(22) {
  grid-column: 4/5;
  grid-row: 10/12;
}

.video-grid > div:nth-of-type(23) {
  grid-column: 5/6;
  grid-row: 11/13;
}

.video-grid > div:nth-of-type(24) {
  grid-column: 1/2;
  grid-row: 9/11;
  /* margin-left: -50px; */
}

.video-grid > div:nth-of-type(25) {
  grid-column: 2/3;
  grid-row: 10/13;
}
